import ActionHandler from '@/js/components/forms/action_handler';
import Filter from '@/js/components/forms/filter';

export default class FilterForm extends ActionHandler {
  constructor(formEl) {
    super();

    this.dom = {
      formEl,
      badge: formEl.querySelector('.badge'),
      filterTitleContainer: formEl.querySelector('.filter-title-container'),
      filterTitle: formEl.querySelector('.filter-title'),
    };

    this.components = {
      filters: [...formEl.querySelectorAll('.filter-item')].map((field) => new Filter(field)),
      dropdownItems: document.querySelectorAll('.dropdown-item'),
      resetButtons: document.querySelectorAll('.btn-filter-reset'),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
      type: null,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
      onFormReset: this.onFormReset.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

    this.components.filters.forEach((filter) => {
      filter.dom.filterEl.addEventListener('change', this.events.onFormSubmit);
    });

    this.components.resetButtons.forEach((resetButton) => {
      resetButton.addEventListener('click', this.events.onFormReset);
    });
  }

  /**
   * @param e {Event}
   */
  onFormSubmit(e) {
    e.preventDefault();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('.dropdown-menu .active')];
    // Initialize form data
    const formData = new FormData(this.dom.formEl);

    this.components.filters.forEach((filter) => {
      filter.dom.items
        .filter((item) => item.classList.contains('active'))
        .forEach((item) => {
          const { name, value } = item.dataset;

          if (!name || !value) {
            return;
          }

          if (filter.props.multiselectable) {
            // Add multi filter
            formData.append(`Filters[${name}][]`, value);
          } else {
            // Add single filter
            formData.append(`Filters[${name}]`, value);
          }
        });
    });

    this.send({ url: this.props.url, formData, action: 'filter' });
  }

  /**
   * @param submitter {HTMLElement|null}
   */
  submit(submitter = null) {
    this.dom.formEl.dispatchEvent(new SubmitEvent('submit', { submitter }));
  }

  onFormReset(e) {
    this.components.filters.forEach((filter) => {
      filter.dom.filterEl.classList.remove('active');
    });

    this.components.dropdownItems.forEach((dropdownItem) => {
      dropdownItem.classList.remove('active');
    });

    this.dom.badge.innerHTML = String(0);
    this.dom.filterTitleContainer.innerHTML = String(this.dom.filterTitle.innerHTML);

    this.submit(e.currentTarget);
  }
}
